var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.disabled)?_c('div',{staticClass:"mb-3",class:{ 'd-none': _vm.$attrs.type === 'hidden' },attrs:{"id":typeof _vm.$attrs.id === 'string' ? 'wrapper-input-' + _vm.$attrs.id : null}},[_c('ValidationProvider',{attrs:{"tag":"div","vid":typeof _vm.$attrs.id === 'string' ? _vm.$attrs.id : null,"rules":_vm.disabled ? null : _vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var valid = ref.valid;
    var errors = ref.errors;
return [(_vm.needLabel)?_c('label',{staticClass:"form-label fw-bold",attrs:{"for":typeof _vm.$attrs.id === 'string' ? _vm.$attrs.id : null}},[_vm._v(_vm._s(_vm.getLabel( typeof _vm.label === "string" && _vm.label !== null && _vm.label.trim() !== "" ? _vm.label : _vm.$attrs.id ))+" "),(!_vm.isRequired(_vm.rules))?_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("(optional)")]):_vm._e()]):_vm._e(),(!_vm.hasSlot('content_' + _vm.$attrs.id))?[(_vm.$attrs.type === 'price')?[_c('b-form-input',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"type":"text","placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null},on:{"keydown":function($event){return _vm.handlePriceInput($event)}}},'b-form-input',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'textarea')?[_c('b-form-textarea',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"type":"text","placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null,"rows":"9"}},'b-form-textarea',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'editor')?[_c('VueEditor',_vm._g(_vm._b({attrs:{"editor-toolbar":_vm.customToolbar,"placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null,"rows":"9"}},'VueEditor',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'date')?[_c('div',{staticClass:"w-100"}),_c('date-picker',{staticClass:"form-control",class:{ 'is-invalid': _vm.rules !== null && errors.length },attrs:{"state":_vm.rules !== null ? (errors.length ? valid : null) : null,"format":"DD-MM-YYYY"},on:{"change":_vm.datepickerValueChanged},model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}}),_c('b-form-input',_vm._g(_vm._b({staticStyle:{"display":"none"},attrs:{"type":"text","placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null}},'b-form-input',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'date-mount')?[_c('div',{staticClass:"w-100"}),_c('date-picker',{staticClass:"form-control",class:{ 'is-invalid': _vm.rules !== null && errors.length },attrs:{"type":"month","state":_vm.rules !== null ? (errors.length ? valid : null) : null},on:{"change":_vm.datepickerValueChanged},model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}}),_c('b-form-input',_vm._g(_vm._b({staticStyle:{"display":"none"},attrs:{"type":"text","placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null}},'b-form-input',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type == 'dateRange')?[_c('date-picker',{staticClass:"form-control",class:{ 'is-invalid': _vm.rules !== null && errors.length },attrs:{"type":"date","range":"","state":_vm.rules !== null ? (errors.length ? valid : null) : null},on:{"change":_vm.timepickerRangeValueChanged},model:{value:(_vm.timepicker),callback:function ($$v) {_vm.timepicker=$$v},expression:"timepicker"}})]:(_vm.$attrs.type === 'time')?[_c('div',{staticClass:"w-100"}),_c('date-picker',{staticClass:"form-control",class:{ 'is-invalid': _vm.rules !== null && errors.length },attrs:{"type":"time","state":_vm.rules !== null ? (errors.length ? valid : null) : null,"format":"HH:mm"},on:{"change":_vm.timepickerValueChanged},model:{value:(_vm.timepicker),callback:function ($$v) {_vm.timepicker=$$v},expression:"timepicker"}}),_c('b-form-input',_vm._g(_vm._b({staticStyle:{"display":"none"},attrs:{"type":"text","placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null}},'b-form-input',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'enum')?[_c('b-form-select',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null}},'b-form-select',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'select')?[_c('v-select',_vm._g(_vm._b({class:{ 'is-invalid': !!errors.length },attrs:{"disabled":_vm.disabled,"reduce":function (filterItem) { return filterItem.value; },"label":"text","value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null}},'v-select',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'hidden')?[_c('input',_vm._g(_vm._b({attrs:{"type":"text","state":_vm.rules !== null ? (errors.length ? valid : null) : null},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'attachment')?[_c('b-form-file',_vm._g(_vm._b({attrs:{"state":Boolean(_vm.value),"accept":"image/png,image/jpeg,application/pdf","placeholder":"Pilih file disini...","drop-placeholder":"Drop file disini...","value":_vm.value}},'b-form-file',_vm.$attrs,false),_vm.listeners))]:(_vm.$attrs.type === 'attachmentExcel')?[_c('b-form-file',_vm._g(_vm._b({attrs:{"state":Boolean(_vm.value),"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","placeholder":"Pilih file disini...","drop-placeholder":"Drop file disini...","value":_vm.value}},'b-form-file',_vm.$attrs,false),_vm.listeners))]:[_c('b-form-input',_vm._g(_vm._b({style:({ width: _vm.$attrs.type === 'number' ? '100%' : null }),attrs:{"disabled":_vm.disabled,"placeholder":typeof _vm.$attrs.placeholder === 'string'
              ? _vm.$attrs.placeholder
              : 'Ketikkan ' +
                _vm.getLabel(
                  typeof _vm.label === 'string' &&
                    _vm.label !== null &&
                    _vm.label.trim() !== ''
                    ? _vm.label
                    : _vm.$attrs.id
                ),"value":_vm.value,"state":_vm.rules !== null ? (errors.length ? valid : null) : null}},'b-form-input',_vm.$attrs,false),_vm.listeners))]]:[(_vm.hasSlot('content_' + _vm.$attrs.id))?_vm._t('content_' + _vm.$attrs.id,null,{"colData":_vm.value}):_vm._e()],_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0] == "{field} is not valid." ? "isi kolom ini rentan 14-16 karakter" : errors[0]))])]}}],null,false,2412464760)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }