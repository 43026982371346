<template>
  <div
    class="mb-3"
    :id="typeof $attrs.id === 'string' ? 'wrapper-input-' + $attrs.id : null"
    v-if="!disabled"
    v-bind:class="{ 'd-none': $attrs.type === 'hidden' }"
  >
    <ValidationProvider
      tag="div"
      :vid="typeof $attrs.id === 'string' ? $attrs.id : null"
      :rules="disabled ? null : rules"
      v-slot="{
        valid,
        errors,
      }"
    >
      <label
        class="form-label fw-bold"
        v-if="needLabel"
        :for="typeof $attrs.id === 'string' ? $attrs.id : null"
        >{{
          getLabel(
            typeof label === "string" && label !== null && label.trim() !== ""
              ? label
              : $attrs.id
          )
        }}
        <span style="font-weight: 400" v-if="!isRequired(rules)"
          >(optional)</span
        ></label
      >
      <template v-if="!hasSlot('content_' + $attrs.id)">
        <template v-if="$attrs.type === 'price'">
          <b-form-input
            :disabled="disabled"
            type="text"
            @keydown="handlePriceInput($event)"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          ></b-form-input>
        </template>
        <template v-else-if="$attrs.type === 'textarea'">
          <b-form-textarea
            :disabled="disabled"
            type="text"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
            rows="9"
          ></b-form-textarea>
        </template>
        <template v-else-if="$attrs.type === 'editor'">
          <VueEditor
            :editor-toolbar="customToolbar"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
            rows="9"
          />
          <!-- <b-form-textarea :disabled="disabled" type="text"
            :placeholder="typeof $attrs.placeholder === 'string' ? $attrs.placeholder : 'Ketikkan ' + getLabel(typeof label === 'string' && label !== null && label.trim() !== '' ? label : $attrs.id)"
            v-on="listeners" v-bind:value="value" :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs" rows="9"></b-form-textarea> -->
        </template>
        <!-- <template v-else-if="$attrs.type === 'textarea'">
          <vue-editor v-model="value" v-on="listeners" v-bind="$attrs" :editorToolbar="customToolbar"></vue-editor>
        </template> -->
        <template v-else-if="$attrs.type === 'date'">
          <div class="w-100"></div>
          <date-picker
            v-bind:class="{ 'is-invalid': rules !== null && errors.length }"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            class="form-control"
            format="DD-MM-YYYY"
            @change="datepickerValueChanged"
            v-model="datepicker"
          ></date-picker>
          <b-form-input
            type="text"
            style="display: none;"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          ></b-form-input>
        </template>
        <template v-else-if="$attrs.type === 'date-mount'">
          <div class="w-100"></div>
          <date-picker
            type="month"
            v-bind:class="{ 'is-invalid': rules !== null && errors.length }"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            class="form-control"
            @change="datepickerValueChanged"
            v-model="datepicker"
          ></date-picker>
          <b-form-input
            type="text"
            style="display: none;"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          ></b-form-input>
        </template>
        <template v-else-if="$attrs.type == 'dateRange'">
          <date-picker
            v-bind:class="{ 'is-invalid': rules !== null && errors.length }"
            type="date"
            range
            :state="rules !== null ? (errors.length ? valid : null) : null"
            class="form-control"
            @change="timepickerRangeValueChanged"
            v-model="timepicker"
          ></date-picker>
        </template>
        <template v-else-if="$attrs.type === 'time'">
          <div class="w-100"></div>
          <date-picker
            v-bind:class="{ 'is-invalid': rules !== null && errors.length }"
            type="time"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            class="form-control"
            format="HH:mm"
            @change="timepickerValueChanged"
            v-model="timepicker"
          ></date-picker>
          <b-form-input
            type="text"
            style="display: none;"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          ></b-form-input>
        </template>
        <template v-else-if="$attrs.type === 'enum'">
          <b-form-select
            :disabled="disabled"
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          ></b-form-select>
        </template>
        <template v-else-if="$attrs.type === 'select'">
          <v-select
            :disabled="disabled"
            v-on="listeners"
            :reduce="(filterItem) => filterItem.value"
            label="text"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
            :class="{ 'is-invalid': !!errors.length }"
          ></v-select>
        </template>
        <template v-else-if="$attrs.type === 'hidden'">
          <input
            type="text"
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          />
        </template>
        <template v-else-if="$attrs.type === 'attachment'">
          <b-form-file
            :state="Boolean(value)"
            accept="image/png,image/jpeg,application/pdf"
            placeholder="Pilih file disini..."
            drop-placeholder="Drop file disini..."
            v-on="listeners"
            v-bind:value="value"
            v-bind="$attrs"
          ></b-form-file>
        </template>
        <template v-else-if="$attrs.type === 'attachmentExcel'">
          <b-form-file
            :state="Boolean(value)"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            placeholder="Pilih file disini..."
            drop-placeholder="Drop file disini..."
            v-on="listeners"
            v-bind:value="value"
            v-bind="$attrs"
          ></b-form-file>
        </template>
        <template v-else>
          <b-form-input
            v-bind:style="{ width: $attrs.type === 'number' ? '100%' : null }"
            :disabled="disabled"
            :placeholder="
              typeof $attrs.placeholder === 'string'
                ? $attrs.placeholder
                : 'Ketikkan ' +
                  getLabel(
                    typeof label === 'string' &&
                      label !== null &&
                      label.trim() !== ''
                      ? label
                      : $attrs.id
                  )
            "
            v-on="listeners"
            v-bind:value="value"
            :state="rules !== null ? (errors.length ? valid : null) : null"
            v-bind="$attrs"
          ></b-form-input>
        </template>
      </template>
      <template v-else>
        <slot
          v-if="hasSlot('content_' + $attrs.id)"
          :name="'content_' + $attrs.id"
          :col-data="value"
        />
      </template>
      <b-form-invalid-feedback>{{
        errors[0] == "{field} is not valid."
          ? "isi kolom ini rentan 14-16 karakter"
          : errors[0]
      }}</b-form-invalid-feedback>
    </ValidationProvider>
  </div>
</template>
<script>
import Vue from "vue";
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);
import { isRequired, formatIdrInput } from "@/_helpers";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

export default {
  components: { DatePicker },
  inheritAttrs: false,
  name: "fg-input",
  props: {
    value: [String, Number, File, Object],
    //   value: {
    //     type: String, Number,
    //     required: true
    //   },
    needLabel: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    // type: {
    //   type: String,
    //   required: true
    // },
    label: {
      type: String,
      required: false,
      default: function() {
        return null;
      },
    },
    rules: {
      type: String,
      required: false,
      default: function() {
        return null;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    // options: {
    //   type: Array,
    //   required: false,
    //   default: function () {
    //     return []
    //   }
    // }
  },
  data() {
    return {
      fileInput: null,
      datepicker: this.value,
      timepicker: this.value,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "left" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
      ],
    };
  },
  mounted() {
    // console.log(typeof this.$attrs.id === 'string')
  },
  watch: {
    disabled: function(newValue, oldValue) {
      if (newValue) {
        this.$emit("input", null);
      }
    },
    // 'fileInput': function (newValue, oldValue) {
    //   this.$emit('input', newValue)
    // }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        // input: this.updateValue,
        //   focus: typeof this.onFocus === 'function' ? this.onFocus : null,
        //   blur: typeof this.onBlur === 'function' ? this.onFocus : null,
        // change: this.onChange
      };
    },
  },
  methods: {
    timepickerRangeValueChanged: function(e) {
      this.$emit('valueDateRange', `${this.$moment(e[0]).format('YYYY-MM-DD')} ~ ${this.$moment(e[1]).format('YYYY-MM-DD')}`)
    },
    hasSlot(name = "default") {
      // if (typeof this.$slots[name] === 'undefined') {
      //   return false
      // }
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    setValue(value) {
      if (this.$attrs.type === "date" || this.$attrs.type === "date-mount") {
        this.datepicker = value !== null ? new Date(value) : null;
      } else if (this.$attrs.type === "time") {
        this.timepicker =
          value !== null
            ? new Date(
                this.$moment().format("YYYY-MM-DD") + " " + value + ":00"
              )
            : null;
      } else if(this.$attrs.type === "date-mount"){
        this.datepicker = value !== null ? new Date(
          this.$moment().format("YYYY-MM")
        ) : null
      } else if (this.$attrs.type === "dateRange") {
        if (value.split("~").length > 0) {
          let dataChange = []
          value.split("~").forEach(data => {
            const sigleDate = data.split("-")
            dataChange.push(new Date(sigleDate[0], sigleDate[1], sigleDate[2]));
          })
          this.timepicker = dataChange;
        }
      }
      this.$nextTick(() => {
        this.$emit("input", value);
      });
    },
    handlePriceInput(evt) {
      setTimeout(() => {
        formatIdrInput(evt.target.value).then((resp) => {
          this.$emit("input", resp);
        });
      }, 10);
    },
    isRequired(rules) {
      return isRequired(rules);
    },
    updateValue(evt) {
      let value = evt.target.value;
      if (!this.touched && value) {
        this.touched = true;
      }
      this.$emit("input", value);
    },
    forceUpdateValue(value) {
      if (!this.touched && value) {
        this.touched = true;
      }
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
    onChange(element) {
      this.$emit("change", element.target.value);
    },
    getLabel(value) {
      return value !== null ? value.replace(/_/g, " ") : null;
    },
    datepickerValueChanged(val) {
      this.$emit(
        "input",
        val !== null ? this.$moment(val).format("YYYY-MM-DD") : null
      );
    },
    timepickerValueChanged(val) {
      this.$emit(
        "input",
        val !== null ? this.$moment(val).format("HH:mm") : null
      );
    },
  },
};
</script>
